
export default {
  namespaced: true,
  state: {
    favorites: [],
    histories: [],
    lastfind: {user:null,gtype:null}
  },
  getters: {
    favorites (state) {
      return state.favorites
    },
    histories (state) {
      return state.histories
    },
    lastfind (state) {
      return state.lastfind
    }
  },
  mutations: {
    setFavorites (state, users) {
      state.favorites = users
    },
    setHistories (state, users) {
      state.histories = users
    },
    setLastFind (state, find) {
      state.lastfind = { ...find }
    },
    // -------------------
    reset (state) {
      state.favorites = []
      state.histories = []
      state.lastuser = null
    }
  },
  actions: {}
}
