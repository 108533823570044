import Vue from 'vue'
import axios from 'axios'

// Vue.prototype.$axios = axios


const _AUTO_API_HOSTURL = env => {
  const lc = location
  //const path = '/ufsystem/'

  // 'http://192.168.124.65:8080' > 'http://192.168.124.65:80' // develop
  if (parseInt(lc.port) === 8080) { // develop
    return lc.protocol + '//' + lc.hostname + ':8000'
    //return 'https://' + lc.hostname
  }
  if (env === 'pro') { // force production
    return lc.protocol + '//' + lc.hostname
  }
/*
  if (lc.protocol === 'https' && parseInt(lc.port) === 443) { // production
    return lc.protocol + '//' + lc.hostname
  }
  if (lc.protocol === 'http' && parseInt(lc.port) === 80) { // production
    //return lc.protocol + '//' + lc.hostname
    return 'https://' + lc.hostname
  }
*/
  return lc.protocol + '//' + lc.hostname
}

// 'http://192.168.124.65:8880'
const axiosInstance = axios.create({
  baseURL: _AUTO_API_HOSTURL('dev'),
  withCredentials: true,
  'Content-Type': 'application/json',
  Accept: 'application/json',
  //timeout: 1000,
  //headers: {'X-Custom-Header': 'foobar'}
})
// alert(_AUTO_API_HOSTURL('dev'))

/*
// interceptor設定
axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const rd = error.response.data
    console.log('error.response.data', rd)
    if (rd && rd.reason === 'unauthentication') { // login expire
      alert('ログインタイムアウトが発生しました。\r\n再度ログインしてください')
      location.href = '/' // to: login page
      // this.href({ name: 'login' })
    }
    if (rd && rd.reason === 'token access denied') { // role error
      alert('権限エラーが発生しました。\r\n該当機能を利用するには正しいユーザで再度ログインしてください')
      location.href = '/' // to: login page
      // this.href({ name: 'login' })
    }
    return Promise.reject(error)
  }
)
*/

// *.vueからはthis.$axiosで呼び出せます
Vue.prototype.$axios = axiosInstance

export { axiosInstance }
