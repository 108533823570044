import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// yarn add vuex-persistedstate

import userState from './user-state'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    userState
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEV,
  plugins: [createPersistedState({
    //key: 'SHOGI-KIF-WARS',
    key: '' + process.env.VUE_APP_STRAGE_KEY,
    // storage: window.sessionStorage
    storage: window.localStorage,
    paths: [
      'userState'
    ],
  })]
})
